.pageWrapper {
  animation-name: slideInPage;
  animation-duration: 1s;
}

@keyframes slideInPage {
  from {
    transform: translateY(200px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.categoryContent {
  & > h2 {
    font-size: 1.5em;
    color: #e7ccb3;

    border-bottom: 1px solid rgb(231 204 179 / 30%);
    margin: 0.5em 0;
  }

  // &:first-of-type {
  //   border-top: 0;
  // }
}

.categoryItem {
  display: flex;
  justify-content: space-between;
  color: #e7ccb3;
  text-align: left;
}

.itemPrice {
  font-weight: bold;
  min-width: fit-content;
}
