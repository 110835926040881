.App {
  text-align: center;
}

.App-logo {
  margin: auto;
  height: 20vmin;
  max-height: 20vmin;
  pointer-events: none;
  transition: max-height 1s ease;
}

.App-main-container {
  background-color: #284930;
  background-image: url('./shadow.png');
  background-repeat: no-repeat;
  background-size: 140%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-header {
  position: fixed;
  width: 100%;
  max-height: 30vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 15px;
  border-radius: 0;
  transition: max-height 1s ease, background-color 1s ease, border-radius 1s ease;
}

.active {
  background-color: #e7ccb3;
  max-height: 70px;
  border-radius: 0 0 10px 10px;
  transition: max-height 1s ease, background-color 1s ease, border-radius 1s ease;
}

.active img {
  max-height: 40px;
  transition: max-height 1s ease;
}

.App-link {
  color: #61dafb;
}

.App-container {
  margin: auto;
  display: flex;
  width: 100%;
  height: calc(50vw - 20px);
  max-width: 450px;
  max-height: 225px;
  animation-name: slideInAppContainer;
  animation-duration: 1s;
}

@keyframes slideInAppContainer {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Menu-item {
  border: 1px solid #e7ccb3;
  border-radius: 10px;
  width: 100%;
  position: relative;
  margin-right: 10px;
}

.Menu-item:last-of-type {
  margin-right: 0;
}

.HomePage-wrapper {
  height: 100%;
  padding: 10px;
  flex: 1;
  justify-content: center;
  display: flex;
  margin-bottom: 20vmin;
  overflow: hidden;
}

.Menu-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  position: static;
  border-radius: 10px;
}

.Menu-item p {
  color: #fff;
  position: absolute;
  bottom: 0;
  background: rgb(40 73 48 / 90%);
  margin: 0;
  overflow: hidden;
  border-radius: 10px;
  padding: 10px;
  font-size: 17px;
  width: 100%;
}

@supports (backdrop-filter: blur(4px)) {
  .Menu-item p {
    backdrop-filter: blur(4px);
    background: rgb(40 73 48 / 50%);
  }
}
